/**
 * 接口域名的管理
 */
// 正式配置
const base = {

  sq: '',//请求代理需要用到
  // sq:'http://wechat.nxptdn.com',
  bd: 'http://139.224.229.153:9250',
  client: 'http://e.nxptdn.com',
  appid: 'wxca0280fb1854f377'
}



//测试环境
// const base = {
//   sq: '/api',//请求代理需要用到
//   //sq:'http://test-wechat.nxptdn.com',
//   bd: 'http://charge-sit-api.nxptdn.com',
//   // bd: 'http://localhost:9090',
//   // bd: "https://app-api.hehaokeji.com/",
//   client: 'http://gzh-sit.nxptdn.com',
//   // appid: 'wx62eb4439eb634ca8'
//   appid: 'wx2c47cb94bb3a6baa'
// }


// const base = {
//   sq: '/api',//请求代理需要用到
//   //sq:'http://test-wechat.nxptdn.com',
//   // bd: 'http://39.98.166.234:9099',
//   bd: 'http://192.168.3.52:9250',
//   // bd: 'http://192.168.0.120:9250',
//   // bd: 'http://localhost:9090',
//   // bd: "https://app-api.hehaokeji.com/",
//   client: 'http://four-dls-mobile.nxptdn.com',
//   // appid: 'wx62eb4439eb634ca8'
//   appid: 'wx9e5e67da26c51a14'
// }





export default base;


